/*What is box2help*/
.domore-project-description-wrapper {
    text-align: center;
}

.domore-project-description-wrapper .domore-project-description-title {
    margin: 0;
    color: #1723CB;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
}

.domore-project-description-wrapper .domore-project-description-content {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.domore-project-description-wrapper .domore-project-description-content-call-to-action {
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 0.5rem;
}

/*END What is box2help*/

/*How to donate*/
.domore-how-to-wrapper {

}

.domore-how-to-wrapper .domore-how-to-title {
    color: #1723CB;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper {
    list-style: none;
    display: inline-table;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step {
    display: inline;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div {
    display: block;
    float: left;
    width: 100%;
    height: 110px;
    background: rgba(252, 29, 55, 0.1);
    text-align: center;
    padding: 16px;
    position: relative;
    margin: 8px 0;
    font-size: 20px;
    text-decoration: none;
    color: #000;
}

@media (min-width: 576px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div {
        width: 163px;
        height: 120px;
        padding: 16px 16px 16px 54px;
        margin: 0 10px 0 0;
    }
}

@media (min-width: 768px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div {
        width: 223px;
        padding: 16px 16px 16px 60px;
    }
}


@media (min-width: 992px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div {
        width: 303px;
        padding: 20px 30px 16px 60px;
    }
}

@media (min-width: 1200px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div {
        width: 363px;
        padding: 20px 30px 16px 60px;
    }
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div:after {
    content: "";
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 40px solid rgba(252, 29, 55, 0.1);
    position: absolute;
    right: -40px;
    top: 0;
    z-index: 1;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div:before {
    content: "";
    border-top: 60px solid transparent;
    border-bottom: 60px solid transparent;
    border-left: 40px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
}

@media (max-width: 575.99px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div:after,
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step > div:before {
        display: none;
    }
}

/*First and last item should not have angles on start/end*/
.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step:first-child > div {
    padding-left: 16px;
}

@media (min-width: 992px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step:first-child > div {
        padding-left: 30px;
    }
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step:first-child > div:before {
    display: none;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step:last-child > div {
    margin-right: 0;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step:last-child > div:after {
    display: none;
}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-label {
    margin: 0;
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
}

@media (min-width: 576px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-label {
        font-size: 14px;
        line-height: 18px;
    }
}

@media (min-width: 768px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-label {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (min-width: 992px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-label {
        font-size: 16px;
        line-height: 22px;
    }
}

@media (min-width: 1200px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-label {
        font-size: 16px;
        line-height: 19px;
    }

}

.domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-title {
    margin: 0;
    color: #FC1D37;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
}

@media (min-width: 576px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-title {
        font-size: 16px;
        line-height: 20px;
    }
}

@media (min-width: 768px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-title {
        font-size: 18px;
        line-height: 18px;
    }
}

@media (min-width: 992px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-title {
        font-size: 18px;
        line-height: 22px;
    }
}

@media (min-width: 1200px) {
    .domore-how-to-wrapper .domore-how-to-steps-wrapper .domore-how-to-step .domore-how-to-step-title {
        font-size: 24px;
        line-height: 28px;
    }
}

/*END How to donate*/

.domore-families-wrapper .domore-families-title {
    color: #000000;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.domore-families-wrapper .domore-families-title .domore-families-total {
    color: #1823CB;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

/*// TODO: Remove this if you don't want the sticky package selector after you choose a family once*/
/*@media (min-width: 1200px) {*/
/*    .domore-packages-wrapper.sticky {*/
/*        position: sticky;*/
/*        z-index: 1;*/
/*        bottom: 0;*/
/*        background-color: #fff;*/
/*    }*/
/*}*/

.domore-packages-wrapper .domore-packages-title {
    color: #000000;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
}

.domore-packages-cards-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: center;
}

.domore-packages-cards-wrapper .domore-package-card {
    width: 350px;
    height: 350px;
    background-color: #fff;
    color: #000;
    border: 5px solid #1723CB;
    border-radius: 20px;
    padding: 0;
}

.domore-packages-cards-wrapper .domore-package-card .domore-package-card-content {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
}

.domore-packages-cards-wrapper .domore-package-card .domore-package-card-content:hover {
    cursor: pointer;
    /*opacity: .8;*/
}

.domore-packages-cards-wrapper .domore-package-card .domore-package-card-image {
    width: 264px;
    height: 264px;
    object-fit: contain;
}

.domore-packages-cards-wrapper .domore-package-card .domore-custom-div {
    width: 264px;
    height: 264px;
    color: black;
    object-fit: contain;
}

.domore-packages-cards-wrapper .domore-package-card .domore-package-amount-wrapper {
    padding: 18px 16px 16px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.domore-packages-cards-wrapper .domore-package-card .domore-package-amount-wrapper .domore-package-amount {
    color: #1823CB;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    display: flex;
    align-items: center;
}

/*When a package is selected*/
.domore-packages-cards-wrapper .domore-package-card.selected {
    border-color: #FC1D37;
    color: #fff;
}

/*When a package is selected*/
.domore-packages-cards-wrapper .domore-package-card.selected .domore-package-amount-wrapper {
    background-color: #FC1D37;
}

/*When a package is selected*/
.domore-packages-cards-wrapper .domore-package-card.selected .domore-package-amount-wrapper .domore-package-amount {
    color: #fff;
}

/*When a package is selected*/
.domore-packages-cards-wrapper .domore-package-card.selected .domore-package-amount-wrapper .domore-package-selected-tick {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 30px;
}

.domore-packages-cards-wrapper .domore-package-card.selected .domore-package-amount-wrapper .domore-package-selected-tick:before {
    /* equal to fa-check icon */
    content: "\f00c";
}


.domore-custom-amount {
    /*background-color: #fff;*/
    color: #000;
    border: 3px solid #1723CB;
    /*border-radius: 20px;*/
    padding: 0;
}

.accordion .accordion-header .accordion-button {
    border: solid 1px #1723CB;
    border-radius: 10px;
    padding: 10px;
    border-color: #1723CB;
    color: #fff;
    background-color: #1723CB;
    content: "\f00c";
}

.accordion .accordion-header .accordion-button.collapsed {
    background-color: #FC1D37;
    border-color: #FC1D37;
}
.accordion .card-header.collapsed:after {
    content: '\f068';
}
