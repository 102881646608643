.domore-navbar {
    background-color: #1723CB;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    height: 115px;
    padding: 0;
}

@media (min-width: 992px) {
    .domore-navbar {
        align-items: flex-end;
    }
}

.domore-navbar .domore-navbar-brand {
    color: #fff;
    padding: 0;
    margin: 10px 30px 20px;
}

.domore-navbar .domore-navbar-brand .domore-navbar-brand-logo {
    width: 152px;
    height: 85px;
    object-fit: contain;
    padding: 0;
    margin: 0;
}

.domore-navbar .domore-navbar-toggler {
    margin: 0 30px;
    border-color: rgba(255, 255, 255, .3);
    padding: 8px 10px;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
    vertical-align: -15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .domore-navbar .domore-navbar-toggler {
        display: none;
    }
}

.domore-navbar .domore-navbar-toggler:hover {
    opacity: .7;
}

.domore-navbar .domore-navbar-toggler .navbar-toggler-icon {
    font-family: "Font Awesome 5 Free";
    background: transparent;
    background-image: unset;
    font-weight: 900;
    width: auto;
    height: auto;
}

.domore-navbar .domore-navbar-toggler .navbar-toggler-icon:before {
    /* equal to fa-bars icon */
    content: "\f0c9";
}

.domore-navbar .domore-navbar-collapse {
    background-color: #1723CB;
    color: #fff;
    margin: 0 30px 25px;
    justify-content: flex-end;
    /*So that it's shown above the other items on mobile*/
    z-index: 1;
}

.domore-navbar .domore-navbar-collapse.collapsing,
.domore-navbar .domore-navbar-collapse.show {
    margin: 0;
    padding: 30px;
    box-shadow: rgb(0 0 0 / 24%) 0 7px 8px;
}

/*If collapse was toggled on, but then screen changed and it had not been toggled off yet*/
@media (min-width: 992px) {
    .domore-navbar .domore-navbar-collapse.collapsing,
    .domore-navbar .domore-navbar-collapse.show {
        margin: 0 30px 25px;
        justify-content: flex-end;
        padding: 0;
        box-shadow: unset;
    }
}

.domore-navbar .domore-navbar-collapse .navbar-nav {
    row-gap: 32px;
    column-gap: 32px;
}

.domore-navbar .domore-navbar-collapse .nav-link {
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
    padding: 0;
}

.domore-navbar .domore-navbar-collapse .nav-link:hover {
    color: #FC1D37;
}

.domore-navbar .domore-navbar-collapse .nav-link:focus {
    color: inherit;
}

.domore-navbar-sub-header {
    background-color: #FC1D37;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
    align-items: center;
    padding: 12px 30px 12px;
    justify-content: space-between;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
}

@media (min-width: 992px) {
    .domore-navbar-sub-header {
        padding-left: 86px;
    }
}

.domore-navbar-sub-header .domore-navbar-sub-header-content {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
}

/* For EN | MK picker margin*/
.domore-navbar-sub-header .domore-navbar-sub-header-content div {
    margin-left: 0 !important;
}
