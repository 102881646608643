/*html,body{*/
/*    min-height: 100vh;*/
/*    min-width: 100vw;*/
/*}*/
/*.parent{*/
/*    height: 100vh;*/
/*}*/
.parent>.row{
    display: flex;
    align-items: center;
    /*height: 100%;*/
}
.col label img{
    height:100px;
    width: 100%;
    cursor: pointer;
    transition: transform 1s;
    object-fit: cover;
}
.col label{
    overflow: hidden;
    position: relative;
}
.imgbgchk:checked + label>.tick_container{
    opacity: 1;
}
/*         aNIMATION */
.imgbgchk:checked + label>img{
    transform: scale(1.25);
    opacity: 0.3;
}
.tick_container {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
    text-align: center;
}
.tick {
    background-color: #1723CB;
    color: white;
    font-size: 16px;
    padding: 6px 12px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}
