.domore-localization-wrapper {
    margin-left: 20px;
    color: #fff;
}

.domore-localization-wrapper .domore-localization-button:hover {
    cursor: pointer;
    opacity: .7;
}

.domore-localization-wrapper .domore-localization-button.selected {
    cursor: default;
    font-weight: 900;
    opacity: 1;
}
