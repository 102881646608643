.domore-families-carousel {
    min-height: 500px;
    padding: 0 0 30px 0;
}

.domore-families-carousel-group {
    display: grid;
    /*grid-template-columns: repeat(3, minmax(min-content, max-content));*/
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), max-content));
    /*grid-template-rows: repeat(2, minmax(min(180px, 100%), max-content));*/
    grid-template-rows: repeat(2, 1fr);
    /*grid-auto-rows: 1fr;*/
    /*grid-auto-columns: 1fr;*/
    row-gap: 30px;
    column-gap: 30px;
    align-items: stretch;
    justify-content: center;
}

.domore-family-item {
    position: relative;
    background: #FFFFFF;
    width: 350px;
    min-height: 120px;
    border: 5px solid rgba(24, 35, 203, 0.103186);
    border-radius: 20px;
    padding: 20px 30px;
    margin: 0;

    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    transition: all 0.5s;

    display: flex;
    flex-direction: column;
}

.domore-families-carousel-group .domore-family-item .domore-family-item-title {
    text-align: left;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
}

.domore-families-carousel-group .domore-family-item.selected {
    border-color: #FC1D37;
}

.domore-families-carousel-group .domore-family-item:focus {
    box-shadow: unset;
}

.domore-families-carousel .carousel-inner {
    height: 100%;
    width: 100%; /*Each left/right indicator takes up 10% of the space*/
    margin: 0 auto; /* Center in middle since we took away 20% of the width */
}

.domore-families-carousel .carousel-inner .carousel-item {
    /*TODO: Uncomment this to add animations when choosing families in carousel*/
    /*transition: unset transform .3s ease-in-out;*/
    transition: unset;
}

@media (min-width: 768px) {
    .domore-families-carousel .carousel-control-prev {
        left: -7%;
    }

    .domore-families-carousel .carousel-control-next {
        right: -7%;
    }
}

.domore-families-carousel .carousel-control-prev,
.domore-families-carousel .carousel-control-next {
    color: #000;
    opacity: 1;
    width: 10%;
}

.domore-families-carousel .carousel-control-prev:hover,
.domore-families-carousel .carousel-control-next:hover {
    opacity: .7;
}

.domore-families-carousel .carousel-control-prev .carousel-control-prev-icon,
.domore-families-carousel .carousel-control-next .carousel-control-next-icon {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 30px;
    background-image: unset;
    background: transparent;
}

.domore-families-carousel .carousel-control-prev .carousel-control-prev-icon:before {
    /* equal to fa-chevron-left icon */
    content: "\f053";
}

.domore-families-carousel .carousel-control-next .carousel-control-next-icon:before {
    /* equal to fa-chevron-right icon */
    content: "\f054";
}

.domore-families-carousel .carousel-indicators button {
    flex: 0 1 auto;
    margin: 0 3px;
    width: 30px;
    height: 4px;
    cursor: pointer;
    text-indent: -999px;
    background-color: #1723CB;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
}

.domore-families-carousel .carousel-indicators button.active {
    background-color: #FC1D37;
    opacity: 1;
}
