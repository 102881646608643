.domore-box-card-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 30px;
    justify-content: center;
}

.domore-box-card-wrapper .domore-box-card {
    width: 350px;
    height: 350px;
    background-color: #fff;
    color: #000;
    border: 5px solid #1723CB;
    border-radius: 20px;
    padding: 22px 25px 25px;
}

.domore-box-card-wrapper .domore-box-card .domore-box-card-image-small {
    width: 100px;
    height: 100px;
    object-fit: contain;
}

.domore-box-card-wrapper .domore-box-card .domore-box-card-description {
    margin: 16px 0 32px;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.domore-box-card-wrapper .domore-box-card .domore-box-card-action-button {
    width: 100%;
    height: 60px;
    margin: 0;
    padding: 6px;
    border-radius: 10px;
    background-color: #1723CB;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
}

.domore-box-card-wrapper .domore-box-card .domore-box-card-action-button:hover {
    opacity: .7;
}

/*Red stats box*/
.domore-box-card-wrapper .domore-box-card.stats {
    background-color: #FC1D37;
    color: #fff;
    /*border: 5px solid #FC1D37;*/
    border-radius: 10px;
}

.domore-box-card-wrapper .domore-box-card.stats .domore-box-card-title {
    margin: 16px 0 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
}

.domore-box-card-wrapper .domore-box-card.stats .domore-box-card-description {
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

.domore-box-card-wrapper .domore-box-card.stats .domore-box-card-num-donations {
    margin: 24px 0 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
}

.domore-box-card-wrapper .domore-box-card.stats .domore-box-card-donated-amount {
    margin: 24px 0 0;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    word-break: break-word;

    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 8px;
    column-gap: 8px;
}

.domore-box-card-wrapper .domore-box-card.stats .domore-box-card-donated-amount .domore-box-card-donated-amount-currency {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: lowercase;
}

/*END Red stats box*/


/*Domore become partner*/
.domore-become-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    row-gap: 30px;
    column-gap: 30px;
}

.domore-become-call-to-action-wrapper:hover {
    text-decoration: none;
    opacity: .7;
}

.domore-become-call-to-action {
    border: 5px solid #1723CB;
    border-radius: 20px;
    padding: 18px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 16px;
    column-gap: 16px;
}

@media (min-width: 992px) {
    .domore-become-call-to-action {
        width: 540px;
        height: 180px;
    }
}

.domore-become-call-to-action .domore-become-call-to-action-content-wrapper {
    flex: 1 1 auto;
}

.domore-become-call-to-action .domore-become-call-to-action-content-wrapper .domore-become-call-to-action-content-title-small {
    margin: 0 0 4px;
    color: #000000;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
}

.domore-become-call-to-action .domore-become-call-to-action-content-wrapper .domore-become-call-to-action-content-title {
    margin: 0 0 0.5rem;
    color: #1723CB;
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;
}

.domore-become-call-to-action .domore-become-call-to-action-content-wrapper .domore-become-call-to-action-content-description {
    margin: 0;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.domore-become-call-to-action .domore-become-call-to-action-link-wrapper {
    flex: 0 0 40px;
}

.domore-become-call-to-action .domore-become-call-to-action-link-wrapper img {
    width: auto;
    height: 60px;
}

/*END Domore become partner*/


/*Partners carousel*/
.domore-partners-awards-wrapper {
    row-gap: 32px;
}

.domore-partners-carousel {
    height: 200px;
}

@media (min-width: 992px) {
    .domore-partners-carousel {
        height: 300px;
    }
}

.domore-partners-carousel .carousel-inner {
    height: 100%;
}

.domore-partners-carousel .carousel-indicators button {
    flex: 0 1 auto;
    margin: 0 3px;
    width: 30px;
    height: 4px;
    cursor: pointer;
    text-indent: -999px;
    background-color: #1723CB;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
}

.domore-partners-carousel .carousel-indicators button.active {
    background-color: #FC1D37;
    opacity: 1
}

.domore-partners-carousel .domore-partners-carousel-image-link {
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.domore-partners-carousel .domore-partners-carousel-image-link .domore-partners-carousel-image {
    width: 100%;
    object-fit: scale-down;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

/*No longer needed since they are shown in 2 columns next to each other*/
/*@media (min-width: 992px) {*/
/*    .domore-partners-carousel .domore-partners-carousel-image-link .domore-partners-carousel-image {*/
/*        width: 100%;*/
/*        object-fit: scale-down;*/
/*        height: 100%;*/
/*    }*/
/*}*/

/*END Partners carousel*/

